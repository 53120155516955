import { Component, Input, OnInit } from '@angular/core';
import { HTTPService } from '../../services/main.service';

export enum ReleaseSteps {
  'SelectPlatform',
  'SelectType',
  'Result'
}

export enum Platforms {
  'None' = 'none',
  'Google' = 'google',
  'Apple' = 'apple'
}

export enum Types {
  'None' = 'none',
  'Link' = 'link',
  'QR' = 'qr',
}

@Component({
  selector: 'app-release-card',
  templateUrl: './release-card.component.html',
  styleUrls: ['./release-card.component.scss']
})
export class ReleaseCardComponent implements OnInit {


  @Input() ClientId = 0;

  IsReleaseActive = false;
  ReleaseSteps = ReleaseSteps;
  Step = ReleaseSteps.SelectPlatform;

  Platforms = Platforms;
  Platform = Platforms.None;

  Types = Types;
  Type = Types.None;

  URL = ''

  constructor(private mainService: HTTPService) { }

  ngOnInit() {
  }

  onClickOpener() {
    this.IsReleaseActive = !this.IsReleaseActive;
    this.Step = ReleaseSteps.SelectPlatform;
    this.Platform = Platforms.None;
    this.Type = Types.None;
    this.URL = '';
  }

  onClickSelectPlatform(platform: Platforms) {
    this.Platform = platform;
    this.Type = Types.None;
    this.Step = ReleaseSteps.SelectType;
  }

  onClickSelectType(type: Types) {
    this.Type = type;
    this.ReleaseCard(()=>{
      this.Step = ReleaseSteps.Result;
    })

  }

  ReleaseCard(success: () => void) {


    this.mainService.RereleaseCard(
      this.ClientId,
      this.Platform,
      this.Type == this.Types.QR ? true : false)
        .subscribe(
          (res) => {
            //console.log("res = ", res);
            this.URL = res.url;
            //console.log("URL = ", this.URL);
            success();
          }
        )

  }

  OpenInNewTab() {
    // var newTab = window.open();
    // newTab.document.body.innerHTML = `<img src="${URL}">`;

    var image = new Image();
        image.src = this.URL;

        var w = window.open("");
        w.document.write(image.outerHTML);
  }

}
