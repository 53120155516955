import { animate, AnimationBuilder, AnimationMetadata, AnimationPlayer, style } from '@angular/animations';
import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: "[wltAnimate]",
})
export class AnimateTextDirective {
  private _player!: AnimationPlayer;

  @Input("wltAnimate")
  set show(show: boolean) {
    if (this._player) {
      this._player.destroy();
    }
    const metadata = show ? this.fadeIn() : this.fadeOut();

    const factory = this.builder.build(metadata);
    this._player = factory.create(this.el.nativeElement);

    this._player.play();
  }

  constructor(private builder: AnimationBuilder, private el: ElementRef) {}

  private fadeIn(): AnimationMetadata[] {
    return [
      style({ opacity: 0 }),
      animate("200ms ease-in", style({ opacity: 1 })),
    ];
  }

  private fadeOut(): AnimationMetadata[] {
    return [
      style({ opacity: 1 }),
      animate("200ms ease-out", style({ opacity: 0 })),
    ];
  }
}
