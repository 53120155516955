import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: "leadZeroPipe",
})
export class LeadZeroPipe implements PipeTransform {
  transform(value: string | number, digits: number): string {
    return `000000${value}`.slice(-digits);
  }
}
