<svg
  class="ham hamRotate ham1"
  [class.active]="isOpen"
  viewBox="0 0 100 100"
  (click)="toggle()"
>
  <path class="line top" d="m 30,33 h 40" />
  <path class="line middle" d="m 30,50 h 40" />
  <path
    class="line bottom"
    d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
  />
</svg>
