import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: 'app-base-menu-item',
  templateUrl: './base-menu-item.component.html',
  styleUrls: ['./base-menu-item.component.scss']
})
export class BaseMenuItemComponent {

  @Input() icon!: string;
  @Input() name!: string;

  get iconPath() {
    return `./assets/img/menu/${this.icon}.svg`;
  }

}
