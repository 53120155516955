import {Injectable} from '@angular/core';
import {Observable, Subject, throwError} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {catchError, debounceTime, tap} from 'rxjs/operators';
import {LoadingService, LoadingStates} from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class HTTPService {
  // ServerURL = 'https://cazyx-wallet-admin.hero?kuapp.com/v1';

  ServerURL = `${environment.backendURL}/v1`;
  ServerAuthURL = `${environment.backendAuthURL}/v1`;
  ServerTgURL = `${environment.backendTgURL}/v1`;

  onOperatorActionReset = new Subject<void>();


  IsLoading$ = new Subject<boolean>();
  IsLoginedChange$ = new Subject<boolean>();
  IsLogined = false;

  IsExpired = false;
  IsExpiredChange$ = new Subject<boolean>();

  IsLoadingChange$ = new Subject<boolean>();

  IsUserChange$ = new Subject<any>();
  User: any = {
    id: null,
    login: '',
    email: '',
    phone: '',
    first_name: '',
    last_name: '',
    avatar: '',
    company_name: '',
    utc_offset: 0,
    notifyWaiter: false,
    notifyOrder: false,
    notifyFeedback: false,
  };

  headers = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
  });

  constructor(
    private http: HttpClient,
    private router: Router,
    public loadingService: LoadingService,
  ) {
    this.IsLoginedChange$.next(false);
    this.IsLoginedChange$.subscribe((val) => {
      let prevIsLogined = this.IsLogined;
      this.IsLogined = val;
      if (this.IsLogined && !prevIsLogined) {
        this.router.navigate(['/', 'base']);
      } else if (this.IsLogined && this.isInsideAuthModule()) {
        this.router.navigate(['/', 'base']);
      }
      if (!this.IsLogined) {
        this.router.navigate(['auth', 'login']);
      }
    });
  }

  BaseInitByToken(data) {
    //console.log("BaseInitByToken", data)
    if (data) {
      if (!this.headers.has('Authorization')) {
        this.headers = this.headers.append('Authorization', data);
      } else {
        this.headers = this.headers.set('Authorization', data);
      }
    }
  }

  private isInsideAuthModule() {
    return window.location.href.includes('auth') ? true : false;
  }

  Login(email: string, password: string) {
    return this.RequestHandler(() => {
      return this.http.post(
        this.ServerAuthURL + '/operator/login',
        {email, password},
      );
    }, this.loadingService.StatesNames.AUTH_LOGIN);
  }

  LoginTg(data:any,partnerId: number) {
    return this.RequestHandler(() => {
      return this.http.post(
        this.ServerTgURL + '/public/auth-operator',
        {...data, establishmentId: partnerId},
      );
    }, this.loadingService.StatesNames.AUTH_LOGIN);
  }

  Logout() {
    return this.http
      .post(this.ServerAuthURL + '/logout', {}, {headers: this.headers})
      .subscribe((res) => {
        localStorage.setItem('token-operator', '');
        localStorage.removeItem('token-operator');
        sessionStorage.clear();
        this.headers = new HttpHeaders({
          "Access-Control-Allow-Origin": "*",
        });
        this.IsLoginedChange$.next(false);
        this.IsExpiredChange$.next(false);
      });
  }

  RequestHandler(runRequest: () => Observable<any>, RequestType = LoadingStates.UNKNOWN): Observable<any> {
    let loadingId = this.loadingService.addLoading(RequestType);
    //console.log(`[Request handler] with ID = ${loadingId}`)
    return runRequest().pipe(
      debounceTime(50),
      tap((dataRes) => {
        //console.log(`${loadingId} : DataRes = `, dataRes)
        this.loadingService.removeLoading(loadingId);
      })
      , catchError((err) => {
        if (err.status === 402) {
          //console.log("err.status")
          this.IsExpired = true;
          this.IsLoginedChange$.next(false)
          this.loadingService.removeLoading(loadingId);
          return throwError(err);
        }
        this.loadingService.removeLoading(loadingId);
        return throwError(err);
      })
    );
  }


  SendPost(method: string, data, type = LoadingStates.UNKNOWN, ServerURL = this.ServerURL) {
    return this.RequestHandler(() => {
      return this.http.post(
        ServerURL + method,
        data,
        {headers: this.headers}
      );
    }, type)

  }

  SendPut(method: string, data, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.put(
        this.ServerURL + method,
        data,
        {headers: this.headers}
      );
    }, type);
  }

  SendPatch(method: string, data, type = LoadingStates.UNKNOWN, ServerURL = this.ServerAuthURL) {
    return this.RequestHandler(() => {
      return this.http.patch(
        ServerURL + method,
        data,
        {headers: this.headers}
      );
    }, type);
  }


  SendGet(method: string, type = LoadingStates.UNKNOWN, ServerURL = this.ServerURL) {
    return this.RequestHandler(() => {
      return this.http.get(
        ServerURL + method,
        {headers: this.headers}
      )
    }, type);
  }


  SendGetText(method: string, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.get(
        this.ServerURL + method,
        {headers: this.headers, responseType: 'text'}
      ).pipe(
        tap(
          (data) => {
            console.log(data, typeof (data))
          }
        )
      );
    }, type);
  }

  SendDelete(method: string, data, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.delete(
        this.ServerURL + method,
        {headers: this.headers}
      );
    }, type)
  }


  GetMe() {
    return this.SendGet('/operator/me', this.loadingService.StatesNames.UNKNOWN, this.ServerAuthURL);
  }

  GetMeCode() {
    return this.SendPost('/operator/code', {}, this.loadingService.StatesNames.UNKNOWN, this.ServerURL);
  }

  find(phone = '', card_number = '', barcode = '', card_id = 0) {
    let str = '';
    if (phone) {
      str = str + `phone=${phone}&`
    }
    if (card_number) {
      str = str + `card_number=${card_number}`
    }
    if (barcode) {
      str = str + `barcode=${barcode}`
    }


    return this.SendGet(`/operator/workplace/cards/${card_id}/client?` + str);
  }


  GetCards() {
    return this.SendGet(`/operator/workplace/cards`, LoadingStates.FIND_PAGE);
  }

  GetClient(id) {
    return this.SendGet(`/operator/client/info/${id}`, LoadingStates.FUNCTION_PAGE);
  }

  BonusAdd(id, sum) {
    return this.SendPost(`/operator/bonus/add/${id}`, sum, LoadingStates.FUNCTION_ACTION);
  }

  BonusWriteOff(id, data) {
    return this.SendPost(`/operator/bonus/dispatch/${id}`, data, LoadingStates.WRITEOFF_ACTION);
  }

  ConfirmBonusWriteOff(id, data) {
    return this.SendPost(`/operator/bonus/confirm/${id}`, data, LoadingStates.SMS_VERIFY);
  }

  GetAvailableBonuses(id, data) {
    return this.SendPost(`/operator/bonus/limit/${id}`, data, LoadingStates.WRITEOFF_ACTION);
  }

  SendHelp(data) {
    return this.SendPost(`/operator/support_messages `, data, LoadingStates.FORM_SUPPORT);
  }

  //


  OperatorBuyLimit(data) {
    return this.SendPost(`/operator/buy/limit/${data.id}`, data, LoadingStates.FUNCTION_PAGE);
  }

  OperatorBuyCalculate(data) {
    return this.SendPost(`/operator/buy/calculate/${data.id}`, data, LoadingStates.FUNCTION_PAGE);
  }

  OperatorBuySMS(data) {
    return this.SendPost(`/operator/buy/sms/${data.id}`, data, LoadingStates.FUNCTION_PAGE);
  }

  OperatorBuyProceed(data) {
    return this.SendPost(`/operator/buy/proceed/${data.id}`, data, LoadingStates.FUNCTION_PAGE);
  }

  RereleaseCard(client_id: number, platform: string, as_qr: boolean) {
    return this.SendPost(`/reissue/generate_short_link`, {
      client_id,
      platform,
      as_qr
    }, LoadingStates.RELEASE_CARD_GENERATE_LINK)
  }

  Calculate(client_id, sum) {
    return this.SendPost(`/operator/workplace/clients/${client_id}/calc`, {sum}, LoadingStates.CLIENT_CALCULATE)
  }

  Buy(client_id, data) {
    return this.SendPost(`/operator/workplace/clients/${client_id}/pay`, data, LoadingStates.CLIENT_PAY)
  }


  GetMyTipsBalance() {
    return this.SendGet('/operator/payment-moneybox', LoadingStates.GET_MY_TIP_BALANCE);
  }

  GetOperatorPaymentHistory(limit: number, page: number) {
    return this.SendGet(`/operator/payment-history?limit=${limit}&page=${page}`, LoadingStates.GET_OPERATOR_PAYMENT_HISTORI)
  }

  PostTipsWithdrawalFunds(data: any) {
    return this.SendPost(`/operator/payment-moneybox/transfer`, data, LoadingStates.POST_TIPS_WITHDRAWAL_FUNDS)
  }
}
