import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: "genderPipe",
})
export class GenderPipe implements PipeTransform {
  gender = {
    male: "мужской",
    female: "женский",
  };
  transform(value: string): any {
    return this.gender[value] || value;
  }
}
