<div class="base-menu" [class.base-menu_close]="!isOpen">
  <div class="base-menu-header">
    <img
      *ngIf="isOpen"
      src="./assets/img/logo.svg"
      alt="Gaze"
      class="responsive-img base-menu-header__logo"
    />
    <ol class="base-menu-breadcrumb" *ngIf="!isOpen">
      <li *ngFor="let breadcrumb of breadcrumbs">
        <span>
          {{ breadcrumb.label }}
        </span>
      </li>
    </ol>
    <app-hamburger
      class="base-menu-header__hamburger"
      [isOpen]="isOpen"
      (onClick)="isOpen = $event"
    ></app-hamburger>
  </div>
  <div class="base-menu-body" *ngIf="isOpen">
    <menu class="base-menu-list base-menu-primary">
      <!--<li class="base-menu-list__elem">
        <app-base-menu-item
          icon="cards"
          name="Бонусная система"
          routerLink="bonus"
          routerLinkActive="base-menu-list__elem_active"
        ></app-base-menu-item>
      </li>-->
      <li class="base-menu-list__elem">
        <app-base-menu-item
          icon="tips"
          name="Чаевые"
          routerLink="tips"
          routerLinkActive="base-menu-list__elem_active"
        ></app-base-menu-item>
      </li>
      <li class="base-menu-list__elem">
        <app-base-menu-item
          icon="tips"
          name="Профиль"
          routerLink="profile"
          routerLinkActive="base-menu-list__elem_active"
        ></app-base-menu-item>
      </li>
      <li class="base-menu-list__elem">
        <app-base-menu-item
          icon="notifications"
          name="Уведомления"
          routerLink="notifications"
          routerLinkActive="base-menu-list__elem_active"
        ></app-base-menu-item>
      </li>
      <!--<li class="base-menu-list__elem">
        <app-base-menu-item
          icon="support"
          name="Тех. поддержка"
          routerLink="support"
          routerLinkActive="base-menu-list__elem_active"
        ></app-base-menu-item>
      </li>-->
      <li class="base-menu-list__elem">
        <app-base-menu-item
          name="Telegram"
          routerLink="telegram"
          routerLinkActive="base-menu-list__elem_active"
        ></app-base-menu-item>
      </li>
    </menu>

    <div class="base-menu-list base-menu-list-secondary">
      <li class="base-menu-list__elem base-menu-list__elem-logout">
        <app-base-menu-item
          icon="logout"
          name="Выход"
          (click)="logout()"
        ></app-base-menu-item>
      </li>
    </div>
  </div>
</div>
