<div class="flex-row">
  <input
    class="form-control qr-link__input"
    type="text"
    [value]="url"
    disabled
  />
  <button class="btn qr-link__btn" (click)="onCopy.emit()">
    <img src="./assets/img/qr-link/copy.svg" class="responsive-img" />
  </button>
</div>
<div class="flex-row qr__row">
  <div class="gen__block">
    <!--<ngx-qrcode
      id="qrcode"
      [width]="512"
      [elementType]="'url'"
      [value]="qr"
      cssClass="aclass"
      errorCorrectionLevel="L"
      class="qr-link__qr"
    >
    </ngx-qrcode>-->
    <qrcode
      id="qrcode"
      [qrdata]="qr"
      [width]="256"
      [errorCorrectionLevel]="'L'"
      class="qr-link__qr"
      (qrCodeURL)="onChangeURL($event)"
    ></qrcode>

  </div>
  <button
    class="btn qr-link__btn qr-link__btn_download"
    (click)="Download()"
  >
    <img src="./assets/img/qr-link/download.svg" class="responsive-img" />
  </button>
</div>
