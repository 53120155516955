import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { NgControl } from "@angular/forms";
import { CoinsPipe } from "./coins.pipe";

@Directive({
  selector: '[coinsReactiveInput]',
})
export class CoinsReactiveDirective implements OnInit {
  @Input() type: 'big' | 'small' = 'big';
  @Input() min = 0;
  coins = 0;

  constructor(private el: ElementRef, private control: NgControl) {}

  ngOnInit(): void {
    const value = this?.el.nativeElement.value;
    this.coins = value;
    this.control?.control?.patchValue(+value, );
    this.el.nativeElement.value = this.transform(+value, this.type, this.min)
  }

  @HostListener('input')
  onEvent() {
    this.input();
  }

  input() {
    let valueInput = this?.el.nativeElement.value;

    if(valueInput != +valueInput) {
      this.control?.control?.setValue( this.coins * 100);
      this.el.nativeElement.value = this.coins;
      return;
    }

    this.coins = +valueInput;
    this.control?.control?.setValue(this.reTransform(+valueInput, this.type, this.min));
    this.el.nativeElement.value = valueInput > 0 ? valueInput : '';
  }

  transform(coins: number, type: 'big' | 'small' = 'big', min = 0): number {
    let v = coins / 100;
    if(type == 'big') {
      let d = coins / 100;
      let df = +d.toFixed(2);
      v = d > df ? +(df + 0.01).toFixed(2) : df;
    } else {
      v = +(v).toFixed(2);
    }
    return v;
  }

  reTransform(coins: number, type: 'big' | 'small' = 'big', min = 0): number {
    let v = +coins.toFixed(2);
    if(type == 'big') {
      let df = coins - v;
      v = df != 0 ? +(v + 0.01).toFixed(2) : coins;
    }
    v = v * 100;
    return v;
  }



}
