<div
  class="modal"
  #Modal
  [@inOutAnimation]="IsOpen"
  *ngIf="IsOpen"
  (click)="Close()"
>
  <div class="modal-main" (click)="$event.stopPropagation()">
    <div class="modal-head" *ngIf="ShowHeader">
      <ng-content select="[modal-header]"></ng-content>
    </div>
    <div class="modal-body">
      <ng-content select="[modal-content]"></ng-content>
    </div>
  </div>
</div>
