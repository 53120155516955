<div class="wrap">
  <div class="input__block" *ngIf="type === 'phone'">
    <input class="block__input" prefix="+" mask="0(000) 000 00 00" [showMaskTyped]="true">
  </div>
  <div class="input__block" *ngIf="type === 'date'">
    <input
      class="startdate"
      bsDatepicker
      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', adaptivePosition: true }"
      [placement]="calendarPosition"
      [maxDate]="maxDate"
    >
  </div>
  <div class="input__block" *ngIf="type === 'male'">
    <div class="check__container">
      <div class="check__block">
        <input type="radio" id="male" name="remember-me" value="remember" formControlName="rememberMe">
        <label class="body-main__label" for="male">муж.</label>
      </div>
      <div class="check__block">
        <input type="radio" id="female" name="remember-me" value="remember" formControlName="rememberMe">
        <label class="body-main__label" for="female">жен.</label>
      </div>
    </div>
  </div>

  <div class="input__block" *ngIf="type === 'text'">
    <input class="block__input" placeholder="Введите имя">
  </div>


</div>
