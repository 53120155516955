<div class="check__wrap">
  <div class="check__top">
    <h2 class="top__title">Чек операции</h2>
    <!--<p class="top__number">№{{CheckNumber}}</p>-->
    <p class="top__card">Карта <span>"{{CardName}}"</span></p>
  </div>
  <div class="check__body">
    <div class="line line--top"></div>
    <div class="body__item body__item--date">
      <p class="item__text text">Дата</p>
      <p class="item__value text">{{Date|date: 'dd.MM.yyyy'}}</p>
    </div>
    <div class="body__item">
      <p class="item__text text">Сумма покупки</p>
      <p class="item__value text">{{Cash}}₽</p>
    </div>
    <div class="body__item " *ngIf="Type==='bonus' || Type==='bonus_discount'">
      <p class="item__text text">Списанные бонусы</p>
      <p class="item__value text">{{WriteOffBonusses}}</p>
    </div>
    <div class="body__item " *ngIf="Type==='bonus' || Type==='bonus_discount'">
      <p class="item__text text" >Бонусы от покупки</p>
      <p class="item__value text">{{ChargeBonuses}}</p>
    </div>

    <div class="body__item " *ngIf="Type==='chop'">
      <p class="item__text text" >Кол-во товаров по акции</p>
      <p class="item__value text">{{CountSaleProduct}} шт.</p>
    </div>
    <div class="body__item " *ngIf="Type==='chop'">
      <p class="item__text text" >Начислено жетонов</p>
      <p class="item__value text">{{CountAddChop}} шт.</p>
    </div>
    <div class="body__item " *ngIf="Type==='chop'">
      <p class="item__text text" >Активация подарка</p>
      <p class="item__value text">{{CountReleaseChop}} шт.</p>
    </div>
    <div class="body__item" *ngIf="Type==='discount' || Type==='bonus_discount'">
      <p class="item__text text">Скидка</p>
      <p class="item__value text">{{SumOfDiscount}}₽</p>
    </div>

    <div class="line line--bottom"></div>
  </div>
  <div class="check__bottom">
    <div class="bottom__summary">
      <p class="summary__text">ИТОГО:</p>
      <p class="summary__value">{{Summary}}₽</p>
    </div>
    <div class="bottom__message">
      <img class="message__img" src="./assets/img/check-mark.svg">
      <p class="message__text">Операция выполнена</p>
    </div>
    <a class="check__close" (click)="Close()">Закрыть</a>

  </div>
</div>
