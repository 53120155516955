import { Directive, ElementRef, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[phone]",
})
export class PhoneDirective {
  InputText = "";

  Mask = "+7 (xxx) xxx-xx-xx";

  constructor(private el?: ElementRef, private control?: NgControl) {}

  @HostListener("input", ["$event"]) onInput(event) {
    this.InputText = event.target.value;
    this.setTextWithMask(this.InputText);
  }

  setTextWithMask(text: string): void {
    //console.log("setTextWithMask");
    const abstractControl = this.control?.control;

    text = text.replace(/\D/g, "");

    if (text.length == 0) {
      this.el.nativeElement.value = "";
      return;
    }
    if (text.length > 11) {
      this.el.nativeElement.value = this.el.nativeElement.value =
        this.el.nativeElement.value.slice(0, 18);
      abstractControl?.setValue(this.el.nativeElement.value);
      return;
    }
    if (text.length > 0 && text.length <= 11) {
      if (text[0] == "8" || text[0] == "7") text = text.slice(1);
      let value = "";
      if (text.length > 0) {
        for (let num of this.Mask) {
          if (text.length > 0) {
            if (num != "x") {
              value += num;
            } else {
              value += text[0];
              text = text.slice(1);
            }
          }
        }
      } else {
        value = "+7 ";
      }

      this.el.nativeElement.value = value;
      abstractControl?.setValue(value);
    }
  }
}
