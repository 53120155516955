import {OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from "@angular/core";
import {SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-qr-link',
  templateUrl: './qr-link.component.html',
  styleUrls: ['./qr-link.component.scss']
})
export class QrLinkComponent {

  @Input() url?: string;
  @Input() qr?: string;

  @Output() onDownload = new EventEmitter<void>();
  @Output() onCopy = new EventEmitter<void>();

  qrCodeDownloadLink:any = '';




  Download(){
    this.onDownload.emit(this.qrCodeDownloadLink);
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url['changingThisBreaksApplicationSecurity'];
  }

}
