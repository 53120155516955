import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-hint-icon",
  template: `
    <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5104 2.48957C12.905 0.884166 10.7704 0 8.5 0C6.22957 0 4.09504 0.884166 2.48957 2.48957C0.884166 4.09504 0 6.22957 0 8.5C0 10.7704 0.884166 12.905 2.48957 14.5104C4.09504 16.1158 6.22957 17 8.5 17C10.7704 17 12.905 16.1158 14.5104 14.5104C16.1158 12.905 17 10.7704 17 8.5C17 6.22957 16.1158 4.09504 14.5104 2.48957ZM8.5 2.32422C9.50695 2.32422 10.3262 3.14344 10.3262 4.15039C10.3262 5.15734 9.50695 5.97656 8.5 5.97656C7.49305 5.97656 6.67383 5.15734 6.67383 4.15039C6.67383 3.14344 7.49305 2.32422 8.5 2.32422ZM10.8242 13.9453H6.17578V12.9492H7.17188V7.96875H6.17578V6.97266H9.82812V12.9492H10.8242V13.9453Z"
        [attr.fill]="Color || '#2175A5'"
      />
    </svg>
  `,
  styles: [``],
})
export class HintIconComponent {
  @Input() Color!: string;
}
