import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-client-input',
  templateUrl: './client-input.component.html',
  styleUrls: ['./client-input.component.scss']
})
export class ClientInputComponent implements OnInit {

  @Input() type = '';
  @Input() Id = 0;
  @Output() OnSendData = new EventEmitter();

  calendarPosition = 'bottom';
  maxDate = new Date();

  constructor() { }

  ngOnInit(): void {
  }

}
