import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: "cardTypeLabelPipe",
})
export class CardTypeLabelPipe implements PipeTransform {
	transform(
		value: string,
		bonusLabel = "Бонус",
		discountLabel = "Скидка",
		bonusDiscountLabel = "Скидка",
		chopLabel = "Chop"
	): any {
		return value == "bonus"
			? bonusLabel
			: value == "discount"
				? discountLabel
				: value == 'bonus_discount'
					? bonusDiscountLabel
					: value == 'chop'
						? chopLabel
						: value;
	}
}
