import {Component, OnInit} from '@angular/core';
import {HTTPService} from './shared/services/main.service';
import {EventsService} from './shared/services/event.services';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'BINDL Сотрудник';

  Subscriptions = []

  isLoading = false;

  ngOnInit(): void {
    this.CheckToken();
    this.main.IsLoadingChange$.subscribe(res => {
      this.isLoading = res;
    })
    this.Subscriptions.push(this.events.IsErrorChange$.subscribe(
      (res)=>{
        if(res && this.events.Error.status) {
          this.toastr.error(this.events.Error.text, '', {
            'positionClass': 'toast-bottom-right',
            'enableHtml': true
          })
        } else{
          this.toastr.clear()
        }
      }
    ));

    this.Subscriptions.push(this.events.IsSuccessChange$.subscribe(
      (res)=>{
        if(res && this.events.Success.status) {
          this.toastr.success(this.events.Success.text, '', {
            'positionClass': 'toast-bottom-center'
          })
        } else{
          this.toastr.clear()
        }
      }
    ));
  }

  //test

  constructor(private main: HTTPService, private events: EventsService, private toastr: ToastrService){}

  CheckToken() {

    if(localStorage.getItem('token-operator')) {
      this.isLoading = true;
      this.main.IsLoadingChange$.next(true);
        this.main.BaseInitByToken(localStorage.getItem('token-operator'));
        this.main.IsLogined = localStorage.getItem('token-operator') ? true : false;


      this.main.GetMe().subscribe(
        (res) => {
          this.isLoading = false;
          this.main.User = res;
          this.main.IsUserChange$.next(true);
          this.main.IsLoginedChange$.next(true);
          this.main.IsLoadingChange$.next(false);
        }, (err) => {
          this.isLoading = false;
          this.main.IsLoadingChange$.next(false);
          this.main.Logout();
        }
      )
    }
  }

ngOnDestroy(): void {
  this.Subscriptions.forEach((subs) => subs.unsubscribe());
}
}
