<div class="release-wrapper">
  <p
    class="release-opener-btn"
    [ngClass]="{ active: IsReleaseActive }"
    (click)="onClickOpener()"
  >
    Выпустить карту <span *ngIf="IsReleaseActive"> (закрыть)</span>
  </p>

  <div *ngIf="IsReleaseActive" class="release-block">
    <div *ngIf="Step >= ReleaseSteps.SelectPlatform" class="platform-block">
      <p class="title">1. Выберите платформу</p>
      <div class="platforms">
        <div
          (click)="onClickSelectPlatform(Platforms.Apple)"
          class="platform"
          [ngClass]="{ selected: Platform == Platforms.Apple }"
        >
          Apple
        </div>
        <div
          (click)="onClickSelectPlatform(Platforms.Google)"
          class="platform"
          [ngClass]="{ selected: Platform == Platforms.Google }"
        >
          Google
        </div>
      </div>
    </div>

    <div *ngIf="Step >= ReleaseSteps.SelectType" class="type-block">
      <p class="title">2. Выберите тип ссылки</p>
      <div class="types">
        <div
          (click)="onClickSelectType(Types.Link)"
          class="type"
          [ngClass]="{ selected: Type == Types.Link }"
        >
          Ссылка
        </div>
        <div
          (click)="onClickSelectType(Types.QR)"
          class="type"
          [ngClass]="{ selected: Type == Types.QR }"
        >
          QR
        </div>
      </div>
    </div>

    <div *ngIf="Step >= ReleaseSteps.Result" class="type-block">
      <p class="title">3. Перейдите по ссылке</p>
      <a *ngIf="Type == Types.Link" target="_blank" [href]="'https://' + URL">{{
        URL
      }}</a>
      <div *ngIf="Type == Types.QR">
        <img style="width: 160px" [src]="URL" />
        <p class="type-block__open" (click)="OpenInNewTab()">
          Открыть в новом окне
        </p>
      </div>
    </div>
  </div>
</div>
