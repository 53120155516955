import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';

import { AppComponent } from './app.component';
import {RouterModule} from '@angular/router';
import {NgxMaskModule} from 'ngx-mask';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {HttpClientModule} from '@angular/common/http';
import {NgSelectModule} from '@ng-select/ng-select';
import {ToastrModule} from 'ngx-toastr';
import {registerLocaleData} from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import {TooltipModule} from "ngx-bootstrap/tooltip";


registerLocaleData(localeRu, 'ru');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule,
    NgSelectModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
    RouterModule.forRoot([
      {path: '', pathMatch: 'full', redirectTo: 'auth'},
      {
        path: 'auth',
        loadChildren: () => {
          return import('./auth/auth.module').then(m => m.AuthModule);
        }
      },
      {
        path: 'base',
        loadChildren: () => {
          return import('./base/base.module').then(m => m.BaseModule);
        }
      }
    ], {}),
    SharedModule,
    TooltipModule.forRoot()
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'ru' }],
  bootstrap: [AppComponent],
})
export class AppModule { }
