import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";

@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss']
})
export class HamburgerComponent {

  @Input() isOpen = true;

  @Output() onClick = new EventEmitter<boolean>();
  toggle(): void {
    this.onClick.emit(!this.isOpen);
  }

}
