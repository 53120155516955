import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

	@Input() text: string = '';
	@Input() type: 'cancel' | 'submit';
	@Input() isDisabled: boolean = false;
	@Input() isHasMinWidth: boolean = true;
	@Input() isAutoWidth: boolean = false;

	@Output() ClickBtn = new EventEmitter();

	constructor() { }

	ngOnInit(): void {
		if (!this.text) {
			if (this.type === 'cancel') this.text = 'Отмена';
			else this.text = 'Отправить';
		}
	}

}
