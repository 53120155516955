import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { IBreadCrumb } from 'src/app/shared/models/Breadcrumb.model';
import { HTTPService } from 'src/app/shared/services/main.service';

@Component({
  selector: 'app-base-menu',
  templateUrl: './base-menu.component.html',
  styleUrls: ['./base-menu.component.scss']
})
export class BaseMenuComponent implements OnInit {

  isOpen = true;
  customersIsActive = false;

  openSubmenuCustomers = false;

  breadcrumbs: IBreadCrumb[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly mainService: HTTPService
  ) {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
  }

  ngOnInit() {
    this._clientsActive();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this._clientsActive();
        this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
        //console.log("breadcrumbs", this.breadcrumbs)
      });
  }


  logout() {
    this.mainService.Logout();
  }

  /**
   * Recursively build breadcrumb according to activated route.
   * @param route
   * @param url
   * @param breadcrumbs
   */
  buildBreadCrumb(
    route: ActivatedRoute,
    url: string = "",
    breadcrumbs: IBreadCrumb[] = []
  ): IBreadCrumb[] {
    //If no routeConfig is avalailable we are on the root path
    let label =
      route.routeConfig && route.routeConfig.data
        ? route.routeConfig.data.breadcrumb
        : "";
    let path =
      route.routeConfig && route.routeConfig.data ? route.routeConfig.path : "";
    //In the routeConfig the complete path is not available,
    //so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: IBreadCrumb = {
      label: label,
      url: nextUrl,
    };
    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label
      ? [...breadcrumbs, breadcrumb]
      : [...breadcrumbs];
    if (route.firstChild) {
      //If we are not on our current path yet,
      //there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  private _clientsActive = () => {
    this.customersIsActive = ["clients", "push", "triggers"].some((path) =>
      this.router.url.includes(path)
    );
  };

}
