import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-tip-body',
  templateUrl: './tip-body.component.html',
  styleUrls: ['./tip-body.component.scss']
})
export class TipBodyComponent implements OnInit {
  @Input() Text = "Tooltip";

  constructor() { }

  ngOnInit(): void {
  }

}
