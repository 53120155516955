import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("0.2s ease-in", style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ModalComponent {
  @Input() IsOpen = true;
  @Input() ShowHeader = false;
  @Output() OnClose = new EventEmitter();

  @ViewChild("Modal") ModalRef!: ElementRef;

  Close() {
    this.IsOpen = false;
    this.OnClose.emit();
  }

  @HostListener("document:keydown.escape", []) onKeydownHandler() {
    this.Close();
  }
}
