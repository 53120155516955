import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgxMaskModule } from "ngx-mask";
import { PhoneDirective } from "./directives/phone.directive";
import { CheckComponent } from "./ui/check/check.component";
import { ClientInputComponent } from "./ui/client-input/client-input.component";
import { HintIconComponent } from "./ui/hint-icon/hint-icon.component";
import { PreloaderComponent } from "./ui/preloader/preloader.component";
import { ReleaseCardComponent } from "./ui/release-card/release-card.component";
import { TipBodyComponent } from "./ui/tip-body/tip-body.component";
import { ButtonComponent } from './ui/button/button.component';
import { HamburgerComponent } from './ui/hamburger/hamburger.component';
import { BaseMenuComponent } from './ui/base-menu/base-menu.component';
import { BaseMenuItemComponent } from './ui/base-menu-item/base-menu-item.component';
import {RouterModule} from '@angular/router';
import {CardTypeLabelPipe} from './Pipes/card-type-label.pipe';
import {GenderPipe} from './Pipes/gender.pipe';
import {LeadZeroPipe} from './Pipes/leadZero.pipe';
import { QrLinkComponent } from './ui/qr-link/qr-link.component';
import {TooltipHintComponent} from './ui/tooltip-hint/tooltip-hint.component';
import {ModalComponent} from './ui-general/modal/modal.component';
import {CoinsDirective} from "./coins/coins.directive";
import {CoinsPipe} from "./coins/coins.pipe";
import {CoinsReactiveDirective} from "./coins/coins-reactive.directive";
import {QRCodeModule} from "angularx-qrcode";
import { AnimateTextDirective } from './directives/animateText.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    NgxMaskModule,
    BsDatepickerModule,
    RouterModule,
    QRCodeModule

  ],
  declarations: [
    ClientInputComponent,
    PreloaderComponent,
    CheckComponent,
    PhoneDirective,
    TipBodyComponent,
    ReleaseCardComponent,
    ModalComponent,
    HintIconComponent,
    ButtonComponent,
    HamburgerComponent,
    BaseMenuComponent,
    BaseMenuItemComponent,
    CardTypeLabelPipe,
    GenderPipe,
    LeadZeroPipe,
    QrLinkComponent,
    TooltipHintComponent,
    CoinsDirective,
    CoinsPipe,
    CoinsReactiveDirective,
    AnimateTextDirective
  ],
  exports: [
    PreloaderComponent,
    CheckComponent,
    TipBodyComponent,
    PhoneDirective,
    ReactiveFormsModule,
    ReleaseCardComponent,
    ModalComponent,
    HintIconComponent,
    ButtonComponent,
    HamburgerComponent,
    BaseMenuComponent,
    CardTypeLabelPipe,
    GenderPipe,
    LeadZeroPipe,
    QrLinkComponent,
    TooltipHintComponent,
    CoinsDirective,
    CoinsPipe,
    CoinsReactiveDirective,
    QRCodeModule,
    AnimateTextDirective
  ],
})
export class SharedModule { }
