import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { NgControl } from "@angular/forms";
import { CoinsPipe } from "./coins.pipe";

@Directive({
    selector: '[coinsInput]',
})
export class CoinsDirective implements OnInit {
    @Input() coins = 0;
    @Input() type: 'big' | 'small' = 'big';
    @Input() min = 0;

    @Output() onCoinsChanged = new EventEmitter<number>();

    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        this.el.nativeElement.value = this.transform(this.coins, this.type, this.min);
    }

    @HostListener('input')
    onEvent() {
        const value = this?.el.nativeElement.value;
        const v = value == +value ? value : this.transform(this.coins, this.type, this.min);
        this.el.nativeElement.value = v;
        this.coins = +v * 100;
        this.onCoinsChanged.emit(+this.coins.toFixed(2));
    }

    transform(coins: number, type: 'big' | 'small' = 'big', min = 0): number {
        let v = coins / 100;
        if(type == 'big') {
          let d = coins / 100;
          let df = +d.toFixed(2);
          v = d > df ? +(df + 0.01).toFixed(2) : df;
        } else { 
          v = +(v).toFixed(2);
        }
       return Math.max(min, v);
    }

    

}