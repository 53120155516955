import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

export enum LoadingStates {
  UNKNOWN = 'UNKNOWN',
  MAIN = 'MAIN',
  AUTH_LOGIN = 'AUTH_LOGIN',
  FORM_SUPPORT = 'FORM_SUPPORT',
  FIND_PAGE = 'FIND_PAGE',
  FUNCTION_PAGE = 'FUNCTION_PAGE',
  FUNCTION_ACTION = 'FUNCTION_ACTION',
  WRITEOFF_ACTION = 'WRITEOFF_ACTION',
  SMS_ACTION = 'FUNCTION_ACTION',
  SMS_VERIFY = 'SMS_VERIFY',
  RELEASE_CARD_GENERATE_LINK = 'RELEASE_CARD_GENERATE_LINK',
  CLIENT_ITEM = 'CLIENT_ITEM',
  CLIENT_PAY = 'CLIENT_PAY',
  CLIENT_CALCULATE = 'CLIENT_CALCULATE',
  GET_MY_TIP_BALANCE = 'GET_MY_TIP_BALANCE',
  GET_OPERATOR_PAYMENT_HISTORI = 'GET_OPERATOR_PAYMENT_HISTORI',
  POST_TIPS_WITHDRAWAL_FUNDS = 'POST_TIPS_WITHDRAWAL_FUNDS',
  GET_OPERATOR_INFO = 'GET_OPERATOR_INFO',
}

export interface LoadingInterface {
  id: string,
  type: string
}

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor() {
  }

  StatesNames = LoadingStates;
  States = Object.keys(LoadingStates).map(x => {
    return {state: x, value: false}
  });
  Loadings: LoadingInterface[] = [];
  IsLoadingChange$ = new Subject<boolean>();

  addLoading(requestType = LoadingStates.UNKNOWN): string {

    /*console.log("\n\n")
    console.log("STATES before adding = ", Object.assign({},this.States));*/

    const id = this.uuidv4();
    this.Loadings.push(
      {
        id,
        type: requestType
      }
    );

    //console.warn( this.States, requestType);
    this.States.find(x => x.state == requestType).value = true;


    /*console.log("STATES afer adding = ", Object.assign({},this.States));
    console.log("=== > Current Loadings { add } = ", Object.assign({},this.Loadings))*/
    this.IsLoadingChange$.next(true);
    //console.log("\n\n")
    return id;
  }

  removeLoading(id: string): LoadingInterface[] {
    /*console.log("\n\n")
    console.log("STATES before deleting = ", Object.assign({},this.States))*/
    const type = this.Loadings.find(x => x.id == id).type;
    this.Loadings = this.Loadings.filter(f => f.id != id);

    let countCurrentType = this.Loadings.filter(x => x.type == type).length;
    this.States.find(x => x.state == type).value = countCurrentType > 0 ? true : false;

    /*console.log("STATES afer deleting = ", Object.assign({},this.States))
    console.log("=== > Current Loadings { remove } = ", Object.assign({},this.Loadings))*/
    this.IsLoadingChange$.next(true);
    //console.log("\n\n")
    return this.Loadings;
  }

  isHasLoadingByType(requestType: LoadingStates) {
    return this.Loadings.findIndex(x => x.type == requestType) > -1 ? true : false;
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  getIdFromArrayStateByType(type: LoadingStates) {
    return this.States.findIndex(x => x.state == type);
  }


}
