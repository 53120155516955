import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss']
})
export class CheckComponent implements OnInit {

  @Input() Type='discount';
  @Input() CardName ='Спорт Мастер';

  @Input() Date = '01.01.02';
  @Input() Cash = '1000';
  @Input() WriteOffBonusses='-500';
  @Input() ChargeBonuses='50';
  @Input() Discount='10%';
  @Input() SumOfDiscount= 0;



  @Input() CountSaleProduct = 0;
  @Input() CountAddChop = 0;
  @Input() CountReleaseChop = 0;


  @Input() Summary='500';
  @Output() OnClose = new EventEmitter();
  @Input() CheckNumber = 0;

  constructor() { }

  ngOnInit(): void {
  }


  Close(){
    this.OnClose.emit();
  }

}
