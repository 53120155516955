import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'coins'
})
export class CoinsPipe implements PipeTransform {
    transform(coins: number, type: 'big' | 'small' = 'big', min = 0): number {
        let v = coins / 100;
        if(type == 'big') {
          let d = coins / 100;
          let df = +d.toFixed(2);
          v = d > df ? +(df + 0.01).toFixed(2) : df;
        } else { 
          v = +(v).toFixed(2);
        }
       return Math.max(min, v);
    }
}