import { Component } from '@angular/core';

@Component({
  selector: "app-tooltip-hint",
  templateUrl: "./tooltip-hint.component.html",
  styleUrls: ["./tooltip-hint.component.scss"],
})
export class TooltipHintComponent {
  constructor() {}
}
